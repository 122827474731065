@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

body {
	margin: 0;
	font-family: 'Titillium Web', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}