:root {
  --cyan: #00bece;
  --darkGrey: #516375;
  --grey: #c0c1c2;
  --lightGrey: #f2f3f4;
  --ultralightGrey: #f7f8f9;
  --red: #cf230a;
  --white: #ffffff;
  --black: #000000;
}

body {
  margin: 0;
  font-family: "Titillium Web", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400;
  color: var(--grey);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section {
  padding-top: 120px;
  padding-bottom: 170px;
}

.logo {
  height: 50px;
}

.loading {
  width: 100%;
  height: auto;
}

.footer {
  font-size: 13px;
  background-color: var(--darkGrey);
  color: var(--white);
}

.privacy h2 {
  line-height: 30px;
  color: var(--darkGrey);
}

.center {
  display: flex;
  justify-content: center;
}

.subheading {
  font-size: 16px;
}

.p-home {
  font-size: 15px;
  line-height: 20px;
}

.link {
  color: var(--cyan);
}

.link:hover {
  color: var(--cyan);
}

.title-big {
  font-size: 50px;
  font-weight: "semibold";
  line-height: 1.3;
  color: var(--darkGrey);
  margin-bottom: 10px;
}

.subtitle {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--grey);
}

.input-error {
  margin-top: 5px;
  color: red;
  font-size: 12px;
}

/* Registrati */

.registrati-upper-row {
  padding: 30px 80px;
  border-bottom: 1px solid var(--lightGrey);
}

.registrati-title {
  margin-bottom: 0;
  font-size: 32px;
  font-weight: "semibold";
  color: var(--darkGrey);
}

.registratri-completa {
  font-size: 22px;
  font-weight: "semibold";
  color: var(--darkGrey);
}

.registrati-sec-row {
  margin-bottom: 31px;
  padding-bottom: 45px;
  border-bottom: 1px solid var(--lightGrey);
}

.registrati-sec {
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: "semibold";
  color: var(--darkGrey);
}

.registrati-sec-name {
  margin-bottom: 10px;
  font-size: 15px;
  color: var(--white);
}

/* Drawer Styles*/
.site-description-item-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme="compact"] .site-description-item-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-p {
  display: block;
  margin-bottom: 20px;
  font-weight: "semibold";
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

[data-theme="compact"] .ant-drawer-body p.site-description-item-p {
  font-size: 14px;
  line-height: 1.66667;
}

/* Notifications */
.ant-notification-notice {
  background-color: #516375 !important;
  color: white;
}

.ant-notification-notice-closable {
  background-color: #516375 !important;
  color: white !important;
}

.ant-notification-notice-message {
  color: white !important;
}

.ant-notification-close-x {
  color: white !important;
}

/* Comune Section */
.comune-section-h2 {
  font-size: 28px;
  font-weight: "semibold";
  color: #516375;
}

/* Drawer */
.drawer-header-title {
  font-weight: "semibold";
  color: #516375;
  line-height: 1;
}

.drawer-header-content {
  color: #516375;
}

.logoHome {
  height: 50px;
  width: 200px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.main-menu {
  z-index: 100;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.08);
}

.nohover:hover {
  border-bottom-color: var(--white) !important;
}

.main-menu-items {
  margin-bottom: -6px;
}

.avatar-uploader > .ant-upload {
  width: 100%;
  height: 164px;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
  height: 164px !important;
  padding: 0;
}

.ant-form-item-label {
  text-align: left;
}

.ant-result-subtitle {
  color: var(--grey);
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
}

.ant-result-title {
  color: var(--darkGrey);
  font-size: 22px;
  line-height: 1.8;
  text-align: center;
  font-weight: "semibold";
}

.ant-result-success .ant-result-icon > .anticon {
  color: var(--cyan);
}

.reduce-font-size {
  font-size: 15px;
}

/*Header Sidebar and Container*/
.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: var(--darkGrey);
  border: 1px solid var(--darkGrey);
}

.header-sidebar-right {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
  background-color: var(--darkGrey);
  padding-right: 40px;
  z-index: 1000;
}

.sidebar-content {
  background-color: var(--lightGrey);
  width: 256px;
  height: 100%;
}

.panel-container {
  padding: 30px;
}

.price-container {
  background-color: var(--lightGrey);
  padding: 5px 8px;
  width: 150px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  color: var(--darkGrey);
}

.price-container-light {
  background-color: rgba(242, 243, 244, 0.5);
  padding: 5px 8px;
  width: 150px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  color: rgba(81, 99, 99, 0.5);
}

@media (min-width: 1128px) and (max-width: 1194px) {
  .reduce-font-size {
    font-size: 14px;
  }
}

@media (min-width: 1061px) and (max-width: 1128px) {
  .reduce-font-size {
    font-size: 13px;
  }
}

@media (min-width: 927px) and (max-width: 1061px) {
  .reduce-font-size {
    font-size: 11px;
  }
}

@media (min-width: 768px) and (max-width: 927px) {
  .reduce-font-size {
    font-size: 8px;
  }
}

@media (max-width: 768px) {
  .main-menu {
    padding-left: 10px;
    padding-right: 3px;
  }

  .main-menu-items {
    width: 56px;
  }

  .panel-container {
    padding: 16px;
  }

  .header-sidebar-right {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }

  .product-image {
    margin-bottom: 30px;
  }
}

@media (max-width: 576px) {
  .ant-alert-description {
    font-size: 12px !important;
  }

  .subheading {
    display: none;
  }

  .subtitle {
    font-size: 18px;
  }

  .ant-layout-header {
    padding: 5px 10px;
  }

  .logo {
    width: 120px;
  }

  .ant-result {
    padding: 32px 15px;
  }

  .title-big {
    font-size: 28px;
  }

  .label {
    font-size: 24px;
  }
}

/*Menu hamburger*/

.menu-hamburger {
  width: 30px;
  align-self: center;
  margin-bottom: 5px;
  padding: 0;
  background-color: var(--darkGrey);
  border: none;
}

.bar1,
.bar2,
.bar3 {
  width: 100%;
  height: 2px;
  background-color: var(--white);
  margin: 6px 0;
  transition: cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s;
}

.change.bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 3px);
  transform: rotate(-45deg) translate(-4px, 3px);
}

.change.bar2 {
  opacity: 0;
}

.change.bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

/* Login */

.login-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: url("./Assets/background.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: scroll;
  overflow-x: hidden;
}

.login-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.home-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.login-benvenuto {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: var(--white);
}

.home-benvenuto {
  text-align: center;
  font-size: 32px;
  font-weight: "semibold";
  color: var(--white);
  margin-bottom: 10px;
}

.home-subtitle {
  margin-top: 0;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: var(--cyan);
  margin-bottom: 20px;
}

.home-comune-subtitle {
  margin-top: 0;
  text-align: center;
  font-size: 26px;
  font-weight: 400;
  color: var(--white);
  margin-bottom: 20px;
}

.login-dati {
  height: 100%;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-result-icon {
  display: flex;
  justify-content: center;
}

/*Registrazione*/
.logo-registrazione-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
  align-items: center;
  height: 100px;
}

.logo-comune {
  margin-left: 20px;
  height: 100px;
}

.titolo-comune {
  height: 100px;
  display: flex;
  align-items: center;
  color: var(--white);
  font-size: 48px;
  font-weight: "semibold";
}

.title-medium {
  font-size: 32px;
  font-weight: "semibold";
  color: var(--darkGrey);
  line-height: 1.3;
}

.logo-registrazione {
  height: 45px;
}

.ant-popover-inner {
  border-radius: 6px;
}

.ant-popover-inner-content {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--white);
}

.ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.7) !important;
  border-right-color: rgba(0, 0, 0, 0.7) !important;
  box-shadow: none !important;
}

@media (min-width: 1128px) and (max-width: 1194px) {
  .reduce-font-size {
    font-size: 14px;
  }
}

@media (min-width: 1061px) and (max-width: 1128px) {
  .reduce-font-size {
    font-size: 13px;
  }
}

@media (min-width: 927px) and (max-width: 1061px) {
  .reduce-font-size {
    font-size: 11px;
  }
}

@media (min-width: 768px) and (max-width: 927px) {
  .reduce-font-size {
    font-size: 8px;
  }
}

@media (max-width: 768px) {
  .main-menu {
    padding-left: 10px;
    padding-right: 3px;
  }

  .main-menu-items {
    width: 56px;
  }

  .header-sidebar-right {
    z-index: 100 !important;
  }

  .panel-container {
    padding: 16px;
  }

  .header-sidebar-right {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }

  .product-image {
    margin-bottom: 30px;
  }

  .title-medium {
    font-size: 30px;
  }

  .titolo-comune {
    height: 70px;
    font-size: 2rem;
  }

  .logo-registrazione-container {
    height: 70px;
  }

  .logo-comune {
    height: 70px;
  }
}

@media (max-width: 576px) {
  .ant-alert-description {
    font-size: 12px !important;
  }

  .subheading {
    display: none;
  }

  .subtitle {
    font-size: 18px;
  }

  .ant-layout-header {
    padding: 5px 10px;
  }

  .logo {
    width: 120px;
  }

  .ant-result {
    padding: 32px 15px;
  }

  .title-big {
    font-size: 28px;
  }

  .label {
    font-size: 24px;
  }

  .title-medium {
    font-size: 26px;
  }

  .titolo-comune {
    height: 70px;
    font-size: 2rem;
    display: flex;
    justify-content: center;
  }

  .logo-registrazione-container {
    height: 70px;
  }

  .logo-registrazione {
    width: 70px;
    height: auto;
  }

  .logo-comune {
    height: 70px;
  }

  .home-benvenuto {
    font-size: 24px;
  }

  .home-subtitle {
    font-size: 24px;
  }

  .home-comune-subtitle {
    font-size: 20px;
  }

  .home-caption {
    display: none;
  }
}

.ant-statistic-content-value {
  color: #516375;
}
